/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Breadcrumbs {
  color: $grey;
  display: inline;
  font-size: $fontSize-xs;
  font-weight: $font-weight-l;
  line-height: 12px;
  padding: 12px 0;
  text-transform: uppercase;
}

.BreadcrumbItem {
  display: inline;
  padding-right: 8px;
  position: relative;

  a {
    margin-right: 9px;
  }

  p {
    display: inline;
  }

  &:after {
    color: $asamGreen;
    font-size: $fontSize-Icon17;
    line-height: 12px;
    position: relative;
    top: 3px;
  }

  &:last-child {

    &:after {
      display: none;
    }
  }
}
