/*------------------------------------*\
  #Colors
\*------------------------------------*/

$asamGreen: #a4d65e;
$asamGreenDarker: #94c155;
$asamGreenDarkest: #83ab4b;
$purple: #e02e84;
$purpleDarker: #c92976;
$purpleDarkest: #b22469;
$orange: #ffc445;
$orangeDarker: #e4af3d;
$orangeDarkest: #cb9c36;
$orangeWarning: #FFA000;
$beige: #b3846e;
$greyLightestPlus: #f5f5f5;
$greyLightest: #e3e3e3;
$greyMiddle: #c4c4c4;
$greyLighterPlus: #cacaca;
$greyLighter: #75787b;
$grey: #53565a;
$greyDarker: #424548;
$greyDarkest: #212224;
$white: #ffffff;
$transparentWhite: rgba(255, 255, 255, 0.80);
$asamWhite: #f8edf3;
$black: #000000;
$skin: #fee3d4;
$skinLighter: #f8edf3;
$skinDarker: #f2ccb7;
$skinDarkest: #dfaa8b;
$skinDarkestPlus: #d19477;
$redError: #e20000;
$redErrorAlpha: rgba(226, 0, 0, 0.1);
$orangeInfo: #ffc445;
$orangeInfoAlpha: rgba(255, 196, 69, 0.1);
$blue: #3b5b96;
$paybackBlue: #0046aa;
$paypalBlue: #0070ba;
$facebook: #3b5b96;
$gold: #b89037;
$transparent: transparent;
$amazonYellow: #fad676;
$amazonYellowHover: #f9ca4f;
$paypalYellow: #ffc439;
$blueDarker: #0046AA;
$darkGreen: #005745;