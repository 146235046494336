/*------------------------------------*\
  #Typography
\*------------------------------------*/
$fontFamily: 'Open Sans', system-ui, sans-serif;
$fontFamilyTertiary: 'Caveat';

$fontSize-xs: 10px;
$fontSize-s: 12px;
$fontSize-sm: 14px;
$fontSize-m: 16px;
$fontSize-l: 18px;
$fontSize-lm: 22px;
$fontSize-xl: 24px;
$fontSize-xxl: 28px;
$fontSize-Icon12: 12px;
$fontSize-Icon14: 14px;
$fontSize-Icon15: 15px;
$fontSize-Icon16: 16px;
$fontSize-Icon17: 17px;
$fontSize-Icon18: 18px;
$fontSize-Icon20: 20px;
$fontSize-Icon24: 24px;
$fontSize-Icon25: 25px;
$fontSize-Icon32: 32px;
$fontSize-Icon38: 38px;
$fontSize-Icon44: 44px;
$fontSize-Icon48: 48px;
$fontSize-Icon58: 58px;
$fontSize-Icon78: 78px;
$fontSize-Icon72: 72px;
$fontSize-title-l: 48px;
$fontSize-title-xl: 50px;

$font-weight-s: 300;
$font-weight-m: 400;
$font-weight-l: 600;
