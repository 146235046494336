/* Icomoon web font with Asam icons */
$iconChevronThinRight: '\e90c';
$iconChevronThinLeft: '\e90d';
$iconX: '\e90e';
$iconCheck: '\e908';
$iconBasket: '\e900';
$iconGlobe: '\e901';
$iconChecker: '\e902';
$iconChevronRight: '\e903';
$iconChevronLeft: '\e904';
$iconChevronDown: '\e905';
$iconChevronUp: '\e906';
$iconSearch: '\e907';
$iconWishlist: '\e909';
$iconUser: '\e90a';
$iconAlertCircle: '\e90b';
$iconMinus: '\e90f';
$iconPlus: '\e910';
$iconLogout: '\e926';
$iconLock: '\e912';
$iconFacebook: '\e913';
$iconPinterest: '\e91c';
$iconYoutubePlay: '\e91d';
$iconEmail: '\e91b';
$iconHeart: '\e91e';
$iconHeartFull: '\e91f';
$iconPresent: '\e920';
$iconAccountPackage: '\e919';
$iconAccountWishlist: '\e918';
$iconAccountRating: '\e917';
$iconAccountNewsletter: '\e916';
$iconAccountRecommendation: '\e915';
$iconAccountCounseling: '\e914';
$iconAccountProfile: '\e91a';
$iconAccountAbo: '\e924';
$iconApple: '\e923';
$iconBasketPlus: '\e925';
$iconPayPal: '\e911';
$iconAmazon: '\ea87';

.icomoon-font {
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}